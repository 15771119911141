import { createRouter, createWebHistory } from 'vue-router';

const Home = () => import('@/views/index.vue');

// Modules
import { Auth } from './modules/auth';
import { createRoutes } from './modules/admin';
import { Errors } from './modules/errors';
import { authMiddleware } from '@/middleware';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: [authMiddleware],
    },
    //dashboard
    ...createRoutes(),
    ...Errors,

    ...Auth,
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    // linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

export default router;
