import { authMiddleware } from '@/middleware';

export const Auth = [
    {
        path: '/auth/login',
        name: 'login-1',
        component: () => import(/* webpackChunkName: "auth-login" */ '@/views/auth/login.vue'),
        meta: { layout: 'auth' },
    },
];
