export function formatDateWithDot(rawDate) {
    if (rawDate) {
        const dateTime = new Date(rawDate);
        const year = dateTime.getFullYear();
        const baseMonth = dateTime.getMonth() + 1;
        const month = baseMonth < 10 ? `0${baseMonth}` : baseMonth;
        const day = dateTime.getDate() < 10 ? `0${dateTime.getDate()}` : dateTime.getDate();
        const hour = dateTime.getHours() < 10 ? `0${dateTime.getHours()}` : dateTime.getHours();
        const minute = dateTime.getMinutes() < 10 ? `0${dateTime.getMinutes()}` : dateTime.getMinutes();

        // console.log(`${dateTime}`)
        return `${hour}:${minute} | ${day}.${month}.${year}`;
    } else {
        return '';
    }
}

export function formatToMMDDYYYY(date, stringDateFormat = 'YYYY-MM-DD') {
    dayjs.extend(customParseFormat);
    if (!isUndefinedOrEmptyStringOrNull(stringDateFormat) && !isUndefinedOrEmptyStringOrNull(date)) {
        const dateParsed = dayjs(date, stringDateFormat);
        return dayjs(dateParsed).format('DD-MM-YYYY');
    } else {
        return '__.__.____';
    }
}

export function isUndefinedOrEmptyStringOrNull(v) {
    if (v === undefined) {
        return true;
    } else if (typeof v === 'string' && v.trim().length === 0) {
        return true;
    } else if (v === null) {
        return true;
    } else {
        return false;
    }
}
