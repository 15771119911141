import { authMiddleware, checkRoleMiddleware } from '@/middleware';

export const ROOT = 'dashboard';

export const SERVICES = 'services';
export const STATISTICS = 'statistics';

export const routes = [
    {
        name: SERVICES,
        can: () => true,
    },
    {
        name: STATISTICS,
        can: () => true,
    },
];

export const createRoutes = () => {
    return routes
        .map((route) => {
            return [
                {
                    path: `/${ROOT}/${route.name}`,
                    name: route.name,
                    meta: {
                        can: () => route.can(),
                    },
                    beforeEnter: [authMiddleware, checkRoleMiddleware],
                    component: () => import(`@/views/admin/${route.name}`),
                },
            ];
        })
        .flat();
};
